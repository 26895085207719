<template>
    <div>
        <div class="row data-form">
            <div class="col-12">
                <div class="mb-4">
                    <h6 class="text-uppercase mb-0">{{ $t('placement_exam_score_upload_info').toUpper() }}</h6>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <ValidationObserver ref="importForm">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <ValidationProvider name="excelFile" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('excel_file')">
                                    <div class="d-flex custom-file-upload">
                                        <b-form-file
                                            v-model="excelFile"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('select_file')"
                                            :drop-placeholder="$t('drop_file')"
                                            ref="fileInput"
                                            multiple></b-form-file>
                                        <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{$t('browse')}}</b-button>
                                    </div>
                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PlacementExamScoreService from "@/services/PlacementExamScoreService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },

    props: {
        placement_exam_id:{
            type: String,
            default: null
        }
    },

    data() {
        return {
            excelFile: null
        }
    },
    metaInfo() {
        return {
            title: this.$t("placement_exam_score_upload_title")
        }
    },
    methods: {

        async sendForm() {
            const isValid = await this.$refs.importForm.validate();

            let formData = new FormData()
            formData.append('file', this.excelFile[0])

            if (isValid) {
                PlacementExamScoreService.Import(this.placement_exam_id, formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$emit('hide')
                }).catch(e => {
                    if (e.status == '403' || e.status == '404' || e.status == '406') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$refs.importForm.errors.excelFile.push(this.$t('api.' + e.data.message));
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    } else {
                        if (e.data.errors.file[0]) {
                            this.$refs.importForm.errors.excelFile.push(e.data.errors.file[0]);
                        }
                    }
                })
            }
        }
    },

    created() {

    }


};
</script>
